export default class Event {
	static get friendlyName() { return 'Event' }
	static get modelName() { return Event.friendlyName.replace( ' ', '' ) }
	static get workItemType() { return Event.friendlyName.toString().replace( ' ', '_' ).toLowerCase() }


	constructor( json = {} ) {
		this.event_id = json.event_id
		this.uid = json.uid
		this.name = json.name
		this.short_name = json.short_name
		this.start_time = json.start_time
		this.home_team = json.home_team
		this.vis_team = json.vis_team
		this.league = json.league
	}


	get modelName() { return Event.modelName }
	get friendlyName() { return Event.friendlyName }
	get workItemType() { return Event.workItemType }
}
